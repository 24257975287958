import { axios, enableDisable2FAConfig, getError } from "lib"
import { toast } from "react-toastify"

// MFA Calls
export const enableDisable2FA = async ({ userId, flag }) => {
    try {
        const res = await axios.post(enableDisable2FAConfig().url, {
            userId,
            flag,
        })
        return res
    } catch (e) {
        toast.error(getError(e))
    }
}
