import { Modal } from "components"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { addTicketComments } from "store"
import { editTicket } from "store"

export const Status = ({ show, setShow, id }) => {
    const { t } = useTranslation("/Components/ns")

    const fields = [
        {
            type: "select",
            name: "ticketStatus",
            placeholder: t("ticketStatus"),
            // options: ['Active', 'Waiting', 'Closed', 'Closed and Locked']?.map(
            options: [
                t("waitingForAdmin"),
                t("waitingForYou"),
                t("closed"),
            ]?.map((el, idx) => ({
                label: el,
                value: idx,
            })),
            title: t("status"),
        },
        {
            type: "textarea",
            name: "comment",
            title: t("comment"),
            placeholder: t("enterComment"),
        },
    ]

    const { ticket, detailsLoading, loading } = useSelector(
        (state) => state?.tickets
    )
    const initialValues = {
        ticketStatus: ticket?.ticketStatus,
        comment: "",
    }

    const dispatch = useDispatch()
    return (
        <Modal
            heading={t("changeStatus")}
            submitText={t("changeStatus")}
            show={show}
            setShow={setShow}
            fields={fields}
            loading={detailsLoading || loading}
            initialValues={initialValues}
            handleSubmit={async (values) => {
                const finalTicketValues = {
                    ...ticket,
                    ticketStatus: Number(values?.ticketStatus),
                }
                // Edit Ticket Assigned To
                await dispatch(editTicket({ data: finalTicketValues }))
                if (values?.comment) {
                    await dispatch(
                        addTicketComments({
                            ticketId: ticket?.id,
                            commentText: values?.comment,
                            isSticky: false,
                            isDraft: false,
                            ticketCommentAction: 0,
                            ticketCommentType: 1,
                        })
                    )
                }
                setShow(false)
            }}
        />
    )
}
