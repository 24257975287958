export const Shield = ({ fill = "#3699FF" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="shield"
            x="0px"
            y="0px"
            viewBox="0 0 32 32"
            width="25"
            height="25"
        >
            <path
                fill={fill}
                d="M29.6,5.2C29.3,5,29,4.9,28.7,5.1c-4.3,1.4-8.7,0.3-12-2.8c-0.4-0.4-1-0.4-1.4,0c-3.3,3.1-7.7,4.2-12,2.8
	C3,4.9,2.7,5,2.4,5.2C2.2,5.4,2,5.7,2,6c0,15.7,6.9,20.9,13.6,23.9C15.7,30,15.9,30,16,30s0.3,0,0.4-0.1C23.1,26.9,30,21.7,30,6
	C30,5.7,29.8,5.4,29.6,5.2z M21.7,13.7l-6,6C15.5,19.9,15.3,20,15,20s-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0
	l2.3,2.3l5.3-5.3c0.4-0.4,1-0.4,1.4,0S22.1,13.3,21.7,13.7z"
            />
        </svg>
    )
}
