import {
    getError,
    axios,
    getDepartmentsConfig,
    getUsersByDepartmentIdConfig,
} from "lib"

import { toast } from "react-toastify"
import {
    getDepartments as getDepartmentsDispatch,
    setDepartmentsLoading,
    getDepartmentUsersDispatch,
    setDepartmentsUsersLoading,
} from "store/Slices/departmentsSlice"

// Get All Departments
export const getDepartments = (isInterval) => {
    return async (dispatch) => {
        !isInterval && dispatch(setDepartmentsLoading(true))
        try {
            const { url, defaultData, config } = getDepartmentsConfig()
            const res = await axios.post(url, defaultData, config)
            dispatch(getDepartmentsDispatch(res?.data?.data))
            !isInterval && dispatch(setDepartmentsLoading(false))
        } catch (e) {
            toast.error(getError(e))
            !isInterval && dispatch(setDepartmentsLoading(false))
        }
    }
}

// Get Departments By Department ID
export const getUsersByDepartmentID = ({ id }) => {
    return async (dispatch) => {
        if (id) {
            dispatch(setDepartmentsUsersLoading(true))
            try {
                const { url, config } = getUsersByDepartmentIdConfig(id)
                const res = await axios.get(url, config)
                dispatch(getDepartmentUsersDispatch(res?.data?.data))
                dispatch(setDepartmentsUsersLoading(false))
            } catch (e) {
                toast.error(getError(e))
                dispatch(setDepartmentsUsersLoading(false))
            }
        }
    }
}
