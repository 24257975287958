import { getConfig } from "lib"
const departmentsConfig = (action) =>
    getConfig({ module: "Departments", action })

const prefix = `/api/departments`

export const getDepartmentsConfig = () => ({
    url: `${prefix}/search`,
    defaultData: {
        advancedSearch: {
            fields: ["brandId"],
            keyword: localStorage.getItem("brandId"),
        },
        keyword: "",
        pageNumber: 0,
        pageSize: 0,
        orderBy: [""],
    },
    config: departmentsConfig("View"),
})

export const getDepartmentsByUserIdConfig = ({ id }) => ({
    url: `/api/departments/getuserdepartments/${id}`,
    config: departmentsConfig("View"),
})

export const getUsersByDepartmentIdConfig = (id) => ({
    url: `/api/departments/getdepartmentusers/${id}`,
    config: departmentsConfig("View"),
})
