import { getConfig } from "./getConfig"

const prefix = "/api/v1.0/client/subusers"

const getSubUserConfig = (action) => getConfig({ module: "SubUsers", action })

// Get subUsers List
export const getSubUsersConfig = () => ({
    url: `${prefix}/getallsubusersbyclientidasync?clientId=${
        JSON.parse(localStorage.getItem("CurrentUser__client")).id || ""
    }`,
    config: getSubUserConfig("View"),
})

// Get subUser by id
export const getSubUserByIDConfig = (id) => ({
    url: `${prefix}/id?id=${id}`,
    config: getSubUserConfig("View"),
})

// Activate subUser by id
export const activateSubUserByIDConfig = (id) => ({
    url: `${prefix}/activate/${id}`,
    config: getSubUserConfig("Update"),
})

// Deactivate subUser by id
export const deactivateSubUserByIDConfig = (id) => ({
    url: `${prefix}/deactivate/${id}`,
    config: getSubUserConfig("Update"),
})

// Add subUsers
export const addSubUsersConfig = () => ({
    url: `${prefix}/add-new-subuser`,
    config: getSubUserConfig("Create"),
})

// Delete subUsers
export const deleteSubUsersConfig = (id) => ({
    url: `${prefix}/delete/${id}`,
    config: getSubUserConfig("Remove"),
})

// Update subUser
export const updateSubUserConfig = (id) => ({
    url: `${prefix}/update-subuser/${id}`,
    config: getSubUserConfig("Update"),
})

// Update subUser permission
export const updateSubUserPermissionsConfig = () => ({
    url: `${prefix}/subusermodulelist`,
    config: getSubUserConfig("Update"),
})
