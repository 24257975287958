import { Modal } from "components"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { addTicketComments } from "store"
import { editTicket } from "store"

export const Priority = ({ show, setShow, id }) => {
    const { ticket, detailsLoading, loading } = useSelector(
        (state) => state?.tickets
    )
    const { t } = useTranslation("/Components/ns")

    const initialValues = {
        ticketPriority: ticket?.ticketPriority,
    }

    const fields = [
        {
            type: "select",
            name: "ticketPriority",
            placeholder: t("selectPriority"),
            options: [t("low"), t("medium"), t("high")].map((el, idx) => ({
                label: el,
                value: idx,
            })),
            title: t("priority"),
        },
        {
            type: "textarea",
            name: "comment",
            title: t("comment"),
            placeholder: t("enterComment"),
        },
    ]

    const dispatch = useDispatch()
    return (
        <Modal
            heading={t("setPriority")}
            submitText={t("setPriority")}
            show={show}
            setShow={setShow}
            loading={loading || detailsLoading}
            handleSubmit={async (values) => {
                const finalTicketValues = {
                    ...ticket,
                    ticketPriority: Number(values?.ticketPriority),
                }
                await dispatch(editTicket({ data: finalTicketValues }))

                if (values?.comment) {
                    await dispatch(
                        addTicketComments({
                            ticketId: ticket?.id,
                            commentText: values?.comment,
                            isSticky: false,
                            isDraft: false,
                            ticketCommentAction: 3,
                            ticketCommentType: 1,
                        })
                    )
                }
                setShow(false)
            }}
            fields={fields}
            initialValues={initialValues}
        />
    )
}
