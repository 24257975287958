import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    bills: [],
    transactions: {
		data: []
	},
    bill: null,
    loading: false,
    unpaidInvoiceCount: 0,
    currentCreditBalance: 0,
    gateways: [],
    userCards: [],
}
const billsSlice = createSlice({
    name: "bills",
    initialState,
    reducers: {
        getBills: (state, { payload }) => {
            state.bills = payload?.filter((bill) => bill.status >= 0)
        },
        getBill: (state, { payload }) => {
            state.bill = payload?.filter((bill) => bill.status > 0)?.length
            state.unpaidInvoiceCount = payload?.filter(
                (bill) => [1, 6].indexOf(bill.status) >= 0
            )?.length
        },
        setBillsLoading: (state, { payload }) => {
            state.loading = payload
        },
        getTransactions: (state, { payload }) => {
            state.transactions = payload
        },
        getCurrentCreditBalance: (state, { payload }) => {
            state.currentCreditBalance = payload ?? 0
        },
        setUserCards: (state, { payload }) => {
            state.userCards = payload
        },
        setGateways: (state, { payload }) => {
            state.gateways = payload
        },
    },
})

const { reducer, actions } = billsSlice
export const {
    getBill,
    getBills,
    setBillsLoading,
    getTransactions,
    getCurrentCreditBalance,
    setGateways,
    setUserCards,
} = actions

export default reducer
