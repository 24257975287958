export const Bell = ({ fill = "#494b74" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g id="notification-bing" transform="translate(-108 -188)">
                <path
                    id="Vector"
                    d="M0,0V3.33"
                    transform="translate(120 194.44)"
                    fill="none"
                    stroke="#3699ff"
                    strokeLinecap="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M8.917,0a6.658,6.658,0,0,0-6.66,6.66v2.1a5.192,5.192,0,0,1-.63,2.28L.357,13.16a2.132,2.132,0,0,0,1.2,3.25,23.34,23.34,0,0,0,14.73,0,2.22,2.22,0,0,0,1.2-3.25l-1.27-2.12a5.23,5.23,0,0,1-.63-2.28V6.66A6.7,6.7,0,0,0,8.917,0Z"
                    transform="translate(111.103 190)"
                    fill="none"
                    stroke="#3699ff"
                    strokeLinecap="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M6.66,0A3.342,3.342,0,0,1,3.33,3.33,3.336,3.336,0,0,1,.98,2.35,3.336,3.336,0,0,1,0,0"
                    transform="translate(116.67 206.82)"
                    fill="none"
                    stroke="#3699ff"
                    strokeWidth="1.5"
                />
                <path
                    id="Vector-4"
                    data-name="Vector"
                    d="M0,0H24V24H0Z"
                    transform="translate(108 188)"
                    fill="none"
                    opacity="0"
                />
            </g>
        </svg>
    )
}
