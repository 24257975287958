import { useDispatch, useSelector } from "react-redux"
import { Table, TicketMenu } from "components"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { checkModule } from "lib/checkModule"
import "./styles.scss"
import { getTickets } from "store"
import { Button } from "antd"
import { axios, getTicketsConfig } from "lib"
import { TicketSearch } from "modules/KnowledgeBase/pages/Articles/pages/View/sections/AdvancedSearch/AdvancedSearch"
import moment from "moment/moment"
import { useTranslation } from "react-i18next"

export const RelatedList = ({ queueList, isSearch, AdvancedSearchOptions }) => {
    const { allTickets, loading } = useSelector((state) => state?.tickets)
    const localData = localStorage.getItem("CurrentUser__client")
    let userId = ""
    if (localData == null || localData === undefined) {
        userId = ""
    } else {
        const data = JSON.parse(localData)
        userId = data.id
    }
    // const departmentsLoading = useSelector((state) => state?.departments?.loading)
    const { user } = useSelector((state) => state?.auth)
    const { deptId } = useParams()
    const tickets = allTickets
    const [searchData, setSearchData] = useState("")

    const currentRoute = ({ id = "" }) =>
        `/client/dashboard/support/tickets/details/${id}`

    const { userModules } = useSelector((state) => state?.modules)

    const { permissions } = checkModule({
        module: "Tickets",
        modules: userModules,
    })

    // Setting data properly
    const [data, setData] = useState([])

    const [permissionsData, setPermissionsData] = useState(null)
    const { t } = useTranslation("/Components/ns")

    useEffect(() => {
        setPermissionsData(permissions)
    }, [userModules])

    useEffect(() => {
        setData([])
        if (tickets?.length) {
            const dataToSet = tickets?.map((b) => {
                return {
                    ...b,
                    key: b?.id,
                }
            })
            const trueFirst = dataToSet
                ?.sort(
                    (a, b) =>
                        new Date(b?.lastModifiedOn).getTime() -
                        new Date(a?.lastModifiedOn).getTime()
                )
                ?.sort((a, b) =>
                    a?.pinTicket === b?.pinTicket ? 0 : a?.pinTicket ? -1 : 1
                )

            setData(trueFirst)
        }
    }, [tickets])

    const navigate = useNavigate()

    // console.log( tickets)

    const columns = [
        {
            title: t("ticketNo"),
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => (a?.id < b?.id ? -1 : 1),
        },
        {
            title: t("ubject"),
            dataIndex: "ticketTitle",
            key: "ticketTitle",
            sorter: (a, b) => (a?.ticketTitle < b?.ticketTitle ? -1 : 1),
        },
        {
            title: t("lastUpdated"),
            dataIndex: "lastModifiedOn",
            key: "lastModifiedOn",
            sorter: (a, b) => (a?.lastModifiedOn < b?.lastModifiedOn ? -1 : 1),
            render: (lastModifiedOn) =>
                moment(lastModifiedOn).format("MM/DD/YYYY hh:mm:ss a"),
        },
        {
            title: t("status"),
            dataIndex: "ticketStatus",
            key: "ticketStatus",
            sorter: (a, b) => (a?.ticketStatus < b?.ticketStatus ? -1 : 1),
            render: (status) => {
                // 0: active, 1: waiting, 2: closed, 3: closed and locked, 4: disabled, 5; follow up
                if (status === 0) {
                    return (
                        <span className="py-[4px] px-[8px] rounded uppercase bg-[#1C3238] text-[#0BB783]">
                            {t("waitingForAdmin")}
                        </span>
                    )
                }

                if (status === 1) {
                    return (
                        <span className="py-[4px] px-[8px] rounded uppercase bg-[#392F28] text-[#FFA800]">
                            {t("waitingForYou")}
                        </span>
                    )
                }

                if (status === 2 || status === 3) {
                    return (
                        <span className="py-[4px] px-[8px] rounded uppercase bg-[#3A2434] text-[#F64E60]">
                            {t("closed")}
                        </span>
                    )
                }

                // if (status === 3) {
                //   return (
                //     <span className="py-[4px] px-[8px] rounded uppercase bg-[#3A2434] text-[#F64E60]">
                //       Closed and Locked
                //     </span>
                //   )
                // }
            },
        },
    ]
    const dispatch = useDispatch()

    const [visible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [popup] = useState(null)

    useEffect(() => {
        ;(async () => {
            await dispatch(getTickets())
        })()
    }, [dispatch, deptId])

    const [values, setValues] = useState({
        ...AdvancedSearchOptions?.searchValues,
    })
    const [searchResults, setSearchResults] = useState("")

    useEffect(() => {
        if (!isSearch && data?.length) {
            navigate(`/client/dashboard/support/tickets/details/${data[0]?.id}`)
        } else if (!isSearch && data?.length < 1) {
            navigate(`/client/dashboard/support/tickets/list`)
        }
    }, [data])

    //Advanced Search
    const inputChangeHandler = (e) => {
        const { name, value } = e.target
        setValues({ ...values, [name]: value })
    }

    const searchTicketHandler = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        const defaultData = {
            advancedSearch: {
                fields: ["ticketTitle"],
                keyword: values?.title,
            },
            pageNumber: 0,
            ticketTitle: values?.title,
            pageSize: values?.numResult ? parseInt(values?.numResult) : 3,
            orderBy: ["lastModifiedOn"],
            ticketStatus: values?.status ? parseInt(values?.status) : null,
            id: values?.id ? values?.id : null,
            ticketPriority: values.priority ? parseInt(values.priority) : null,
            clientEmail: values?.email ? values?.email : null,
            clientId: user?.parentID ? user?.parentID : user?.id,
            startDate: values?.dateAdded?.start
                ? values?.dateAdded?.start
                : null,
            endDate: values?.dateAdded?.end ? values?.dateAdded?.end : null,
            departmentId: values?.department ? values?.department : null,
            // assignedTo: userId,
        }

        const { url, config } = getTicketsConfig()

        const res = await axios.post(url, defaultData, config)

        setIsLoading(false)
        if (res?.status === 200) {
            setSearchResults(res?.data?.data?.length)
            setData(res?.data?.data)
        }
    }

    const onSearchHandler = async (data) => {
        setSearchData(data)
    }

    useEffect(() => {
        const fetchAsync = async () => {
            try {
                const defaultData = {
                    pageNumber: 0,
                    keyword: searchData,
                    orderBy: ["lastModifiedOn"],
                    advancedSearch: {
                        fields: ["clientId"],
                        keyword: user?.parentID ? user?.parentID : user?.id,
                    },
                    // assignedTo: userId,
                }

                const { url, config } = getTicketsConfig()

                const res = await axios.post(url, defaultData, config)
                if (res?.status === 200) {
                    setSearchResults(res?.data?.data?.length)
                    setData(res?.data?.data)
                }
            } catch (err) {
                console.log(err)
            }
        }

        fetchAsync()
    }, [])

    return (
        <>
            <div className={`p-[40px] bg-[#1E1E2D] rounded-[8px] mt-2`}>
                {searchResults !== "" && (
                    <div className="text-[#fff] text-md font-medium text-right">
                        {searchResults === 0
                            ? t("noTicketsFoundInSearch")
                            : `
          ${t("totalTicketsMatchingFound")} : ${searchResults}`}
                    </div>
                )}

                {isSearch && (
                    <TicketSearch
                        AdvancedSearchOptions={AdvancedSearchOptions}
                        values={values}
                        setValues={setValues}
                        OnChange={inputChangeHandler}
                        onSubmit={searchTicketHandler}
                        isLoading={isLoading}
                    />
                )}
            </div>

            <div className={`p-[40px] bg-[#1E1E2D] rounded-[8px] mt-2`}>
                <div>
                    <Table
                        columns={columns}
                        loading={loading}
                        data={data}
                        fieldToFilter="id"
                        permissions={permissionsData}
                        hideSearch={true}
                        onSearchHandler={onSearchHandler}
                        searchValue={searchData}
                        searchText={t("search")}
                        additionalBtns={[
                            {
                                text: t("startTicket"),
                                onClick: () => {
                                    navigate(
                                        "/client/dashboard/support/tickets/generate-ticket"
                                    )
                                },
                            },
                        ]}
                        editAction={(record) => (
                            <>
                                <Button
                                    onClick={() =>
                                        navigate(
                                            currentRoute({ id: record?.id })
                                        )
                                    }
                                >
                                    {t("viewDetails")}
                                </Button>
                            </>
                        )}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    navigate(currentRoute({ id: record?.id }))
                                },
                            }
                        }}
                    />
                    <TicketMenu {...popup} visible={visible} />
                </div>
            </div>
        </>
    )
}
